<template lang="pug">
  .design.additional-services
    .design__container
      h3.title.design__title ІНДИВІДУАЛЬНИЙ ДИЗАЙН
      .text.design__text
        p Кастомізуємо корпус терміналу під будь-які потреби, колір транспортного засобу або середовище застосування.
      div.swiper__slider
        div(ref="designSlider").swiper.design__slider.additional-services__slider
          .swiper-wrapper
            div(v-for="item in designList").swiper-slide
              .design__img
                img(
                  :src="item.image"
                  alt="img"
                )
          .swiper-button-prev.arrow__prev
          .swiper-pagination
          .swiper-button-next.arrow__next
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
// images
import Design1 from '@/assets/images/design/01.png'
import Design2 from '@/assets/images/design/02.png'
import Design3 from '@/assets/images/design/03.png'
import Design4 from '@/assets/images/design/04.png'

export default {
  name: 'DesignBlock',
  data () {
    return {
      designList: [
        { image: Design1 },
        { image: Design2 },
        { image: Design3 },
        { image: Design4 }
      ]
    }
  },
  mounted () {
    new Swiper(this.$refs.designSlider, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 1,
      loop: true,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        767: {
          slidesPerView: 3
        }
      }
    })
  }
}
</script>
